<template>
  <div>
    <vue-headful :title="pageTitle" />
    <div class="has-text-centered has-background-primary" style="margin-bottom: 20px;">
      <h1
        class="is-size-6 has-text-white"
        style="padding: 5px 0px"
      >{{ pageheading.toLocaleUpperCase() }}</h1>
    </div>
    <div style="max-width: 95%; margin: auto;">
      <div v-if="isLoading">
        <Loading />
      </div>
      <div v-else id="body-content-area">
        <div id="pagelayout">
            <div class="questionnaires-header">
              <div id="dateRangeSelector" class="column">
                <label>Questionnaire Year:</label>&nbsp;&nbsp;<span style="font-size: .8em; color: #808080">{{ yearFilter }}</span>
                <br />
                <kendo-dropdownlist id="ddlReportDateRange" name="ddlReportDateRange"
                                    v-model="selectedYear"
                                    :data-source="yearRanges"
                                    :data-text-field="'text'"
                                    :data-value-field="'value'"
                                    @select="onYearSelect">
                </kendo-dropdownlist>
              </div>
              <div class="column" style="text-align: center;">
                <div class="heading">{{yearFilter}} Annual Questionnaires</div>
                <div class="subheading" v-if="totalRecords > 0">{{ returned }} of {{ totalRecords }} Returned ({{ (returned / totalRecords) * 100 | formatPercent }})</div>
              </div>
              <div class="column"></div>
            </div>
          </div>
          <div class="grid-header-controls">
            <div class="level-left">
              <div class="control grid-button">
                <a class="button is-light" @click="clearFilters">
                  <span>Clear Filters</span>
                </a>
              </div>
            </div>
            <div class="level-right">
              <div class="control">
                <a class="button is-accent" @click="getCsvExport">
                  <span class="icon">
                    <i class="fal fa-file-csv"></i>
                  </span>
                  <span>
                    Export Questionnaires
                  </span>
                </a>
              </div>
            </div>
          </div>
            <Grid :filter="filter"
                  :data-items="questionnaireItems"
                  :selected-field="selectedField"
                  :sortable="true"
                  :sort="sort"
                  :filterable="true"
                  :pageable="pageable"
                  :page-size="pageSize"
                  :skip="skip"
                  :take="take"
                  :total="totalRecords"
                  :columns="columns"
                  @filterchange="filterChangeHandler"
                  @sortchange="sortChangeHandler"
                  @pagechange="pageChangeHandler"
                  @selectionchange="onSelectionChange"
                  @headerselectionchange="onHeaderSelectionChange">
              <template v-slot:editTemplate="{props}">
                <td>
                  <router-link class="bcc-color" :to="{ name: 'EditQuestionnaire', params: { id: `${props.dataItem.id}`, model: `${JSON.stringify(props.dataItem)}`, skip: `${skip}` }}">
                    {{props.dataItem.name}}
                  </router-link>
                </td>
              </template>
              <template v-slot:filterSlotTemplate="{props, methods}">
                <div class="k-filtercell">
                  <div class="k-filtercell-wrapper">
                    <input type="text" class="k-textbox" :id="`${props.field}`" :value="props.value" @input="(ev) => {methods.change({operator: 'contains', field: props.field, value: ev.target.value, syntheticEvent: ev});}">
                  </div>
                </div>
              </template>
            </Grid>

          <div style="height: 16px;">&nbsp;</div>

          <div id="buttongroup" style="padding-top: 20px;">
            <div class="level">
              <div class="level-left">
                <div class="field is-grouped">
                  <div class="control">
                    <a class="button is-accent">
                      <span class="icon">
                        <i class="fal fa-plus-square"></i>
                      </span>
                      <span>
                        <router-link :to="{ name: 'NewQuestionnaire', params: {  }}" class="has-text-white">Add
                          Questionnaire</router-link>
                      </span>
                    </a>
                  </div>
                  <div class="control">
                    <a class="button is-dark" @click="confirmDelete" :disabled="!hasSelection">
                      <span class="icon">
                        <i class="fal fa-trash-alt"></i>
                      </span>
                      <span>Delete Questionnaire{{ hasPluralSelection ? 's' : '' }}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="delete-modal" class="modal is-active" v-if="showDeleteModal">
            <div class="modal-background" @click="showDeleteModal = false"></div>
            <div class="modal-card">
              <header class="modal-card-head has-bg-danger">
                <div class="modal-card-title has-bg-danger">Confirm Delete</div>
                <a class="delete" aria-label="close" @click="showDeleteModal = false"></a>
              </header>
              <section class="modal-card-body">
                <div class="content">
                  Type "<b>delete</b>" to confirm that you want to delete one or more items.
                </div>
                <div class="field">
                  <div class="control">
                    <input type="text" class="input" placeholder="type `delete` and then click confirm" v-focus
                      v-model.trim="confirmationText" @keydown.enter.prevent="deleteIfConfirmed" />
                  </div>
                </div>
              </section>
              <footer class="modal-card-foot">
                <a class="button is-danger" @click="deleteIfConfirmed" :disabled="confirmationText !== 'delete'">
                  <span class="icon">
                    <i class="fal fa-trash-alt"></i>
                  </span>
                  <span>Confirm</span>
                </a>
                <a class="button" @click="showDeleteModal = false">Cancel</a>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import Vue from 'vue'
import Loading from './Loading'
import { mapState } from 'vuex'
import { activeSite } from '../vuex-actions'
import { orderBy, filterBy } from '@progress/kendo-data-query'
import { Grid } from '@progress/kendo-vue-grid'
import { DropDownList } from '@progress/kendo-dropdowns-vue-wrapper'
import Papa from 'papaparse'

let pageName = 'Questionnaires'
let storageKey = pageName

Vue.filter('formatPercent', function (value) {
  if (value) {
    return value.toFixed(0) + '%'
  } else {
    return '0%'
  }
})
// https://stackoverflow.com/a/1909508
function debounce (fn, ms) {
  let timer = 0
  return function (...args) {
    clearTimeout(timer)
    timer = setTimeout(fn.bind(this, ...args), ms || 0)
  }
}

// https://stackoverflow.com/a/39983194
function downloadFile (fileName, urlData) {
  let aLink = document.createElement('a')
  aLink.download = fileName
  aLink.href = urlData

  let event = new MouseEvent('click')
  aLink.dispatchEvent(event)
}

export default {
  components: {
    Loading,
    'Grid': Grid,
    'kendo-dropdownlist': DropDownList
  },
  data () {
    return {
      yearFilter: new Date().getFullYear(),
      yearRanges: [
        { text: 'Current Year', value: '1' },
        { text: 'Last Year', value: '2' },
        { text: 'All', value: '3' }
      ],
      selectedYear: '1',
      input: {
        name: '',
        id: null,
        city: '',
        stateCode: '',
        year: ''
      },
      props: {
        grid: Grid,
        field: String,
        filterType: String,
        value: [Number, String, String, Number, String, String, String, String],
        operator: String
      },
      questionnaires: [],
      selectedRows: [],
      selectedField: 'selected',
      isLoading: false,
      showFilters: false,
      showDeleteModal: false,
      confirmationText: '',
      staticColumns: [
        {
          field: 'id',
          title: 'Id',
          filterable: false,
          hidden: true
        },
        {
          field: 'year',
          title: 'Year',
          filterable: false
        },
        {
          field: 'name',
          title: 'Questionnaire Name',
          width: '320px',
          cell: 'editTemplate',
          filterCell: 'filterSlotTemplate'
        },
        {
          field: 'institutionName',
          title: 'Institution',
          width: '320px',
          filterCell: 'filterSlotTemplate'
        },
        {
          field: 'institutionId',
          title: 'ID',
          filterCell: 'filterSlotTemplate'
        },
        {
          field: 'city',
          title: 'City',
          filterCell: 'filterSlotTemplate'
        },
        {
          field: 'state',
          title: 'State',
          filterCell: 'filterSlotTemplate'
        },
        {
          field: 'planType',
          title: 'Plan Type',
          filterCell: 'filterSlotTemplate'
        },
        {
          field: 'status',
          title: 'Return Status',
          filterCell: 'filterSlotTemplate'
        }
      ],
      skip: 0,
      take: 20,
      pageSize: 20,
      page: 1,
      returned: 0,
      totalRecords: 0,
      pageable: {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: true,
        previousNext: true
      },
      payload:
      {
        filter:
        {
          dateFilter:
          {
            startDate: null,
            endDate: null
          }
        },
        limit: 20,
        offset: 0
      },
      pageheading: pageName,
      sort: [
        { field: 'id', dir: 'asc' }
      ],
      filter: {
        logic: 'and',
        filters: []
      }
    }
  },
  watch: {
    async $route (to, from) {
      let reload = await this.loadQueryParams(to.query)
      if (reload) {
        await this.goToPage(to.query.page, this.getQuestionnaires)
      }
    }
  },
  computed: {
    ...mapState([activeSite]),
    pageTitle () {
      return pageName + ' - ' + this.activeSite.displayName
    },
    lastpage () {
      return Math.ceil(this.totalRecords / this.perPage)
    },
    questionnaireItems: function () {
      if (this.questionnaires.length === 0) { return [] }

      let filtered = filterBy(this.questionnaires, this.filter)
      return orderBy(filtered, this.sort)
    },
    columns () {
      return [
        { field: 'selected', width: '50px', filterable: false, headerSelectionValue: this.areAllSelected },
        ...this.staticColumns
      ]
    },
    hasSelection () {
      return this.selectedRows.length > 0
    },
    hasPluralSelection () {
      return this.selectedRows.length > 1
    },
    areAllSelected () {
      let selected = this.questionnaires.findIndex(item => item.selected === false)
      let isUndefined = this.questionnaires.findIndex(item => item.selected === undefined)

      return selected === -1 && isUndefined === -1
    }
  },
  async created () {
    if (localStorage.QuestionnairesSkip) {
      this.skip = JSON.parse(localStorage.getItem(`${storageKey}Skip`))
      localStorage.removeItem(`${storageKey}Skip`)
    }

    if (localStorage.QuestionnairesFilters) { this.filters = JSON.parse(localStorage.getItem(`${storageKey}Filters`)) }

    if (localStorage.QuestionnairesSort) { this.sort = JSON.parse(localStorage.getItem(`${storageKey}Sort`)) }

    if (localStorage.QuestionnairesPageSize) {
      let pageSize = JSON.parse(localStorage.getItem(`${storageKey}PageSize`))
      this.take = pageSize
      this.perPage = pageSize
      this.pageSize = pageSize
    }

    await this.loadQueryParams(this.$route.query)
    await this.checkPage(this.getQuestionnaires)
  },
  updated () {
    // Manually repopulate filter values after server-side querying
    if (this.filters) {
      this.filters.forEach(element => {
        var filterInput = document.getElementById(element.field)
        if (filterInput) { filterInput.value = element.value }
      })
    }
  },
  methods: {
    onHeaderSelectionChange (event) {
      let checked = event.event.target.checked
      Vue.set(this, 'questionnaires', this.questionnaires.map((item) => { return { ...item, selected: checked } }))

      if (checked) {
        this.selectedRows = this.questionnaires
          .filter(function (e) {
            return e.selected === true
          })
          .map(function (i) {
            return i.id
          })
      } else {
        this.selectedRows = []
      }
    },
    onSelectionChange (event) {
      let checked = event.event.target.checked
      const id = event.dataItem.id
      if (checked) {
        this.selectedRows.push(id)
      } else {
        var idx = this.selectedRows.indexOf(id)
        if (idx > -1) {
          this.selectedRows.splice(idx, 1)
        }
      }
      Vue.set(event.dataItem, this.selectedField, event.dataItem[this.selectedField])
    },
    async deleteIfConfirmed (event, data) {
      if (this.confirmationText === 'delete') this.deleteSelected('/questionnaires')
      /* {
        this.showDeleteModal = false
        this.confirmationText = ''

        try {
          await this.axios.post('/questionnaires/delete/', this.selectedRows)
          await this.refreshData()
          this.successToast('Deleted!')
        } catch (err) {
          this.handleApiErr(err)
        }
      }
      */
    },
    onYearSelect: async function (e) {
      const selectedOptionValue = e.dataItem.value
      this.skip = 0
      try {
        this.selectedYear = selectedOptionValue

        if (selectedOptionValue === '1') { this.yearFilter = new Date().getFullYear() }
        if (selectedOptionValue === '2') { this.yearFilter = new Date().getFullYear() - 1 }

        this.$router.push({ query: Object.assign({}, this.$route.query, { year: this.yearFilter }) })
        await this.getQuestionnaires()

        let queryParams = {
          yearFilter: this.yearFilter
        }

        localStorage.setItem(`${storageKey}Selection`, JSON.stringify(queryParams))

        this.$router.push({ query: Object.assign({}, this.$route.query, { selectedYear: selectedOptionValue }) })
      } catch (e) {
        this.handleApiErr(e)
      }
    },
    async applyFilters () {
      this.goToPage(1, this.getQuestionnaires)
    },
    async resetFilters () {
      this.input.name = ''
      this.input.id = ''
      this.input.city = ''
      this.input.stateCode = ''
      this.$router.push({ query: { page: 1 } }).catch(err => { this.handleApiErr(err) })
      await this.checkPage(this.getQuestionnaires)
    },
    async refreshData () {
      // TODO: should do search conditional here so that getQuestionnaires can be simplified to be the same as all others
      // TODO: make generic search call that is mixin and takes payload built on getSearchPayload() convention
      await this.getQuestionnaires()
    },
    loadQueryParams (query) {
      let reload = false
      return reload
    },
    async getSearchQuery () {
      this.payload.limit = this.perPage || 20
      this.payload.offset = this.skip || 0

      this.payload.filter.dateFilter.startDate = new Date(this.yearFilter, 0, 1) // 0 based month
      this.payload.filter.dateFilter.endDate = new Date(this.yearFilter, 11, 31)

      if (this.filters) { this.payload.filter.gridFilters = this.filters } else { this.payload.filter.gridFilters = [] }

      if (this.sort.length) { this.payload.sort = this.sort[0].field + ' ' + this.sort[0].dir }
    },
    async getCsvExport () {
      let route = `/questionnaires/export/`
      let response = await this.axios.post(route, this.payload)

      let userCsv = Papa.unparse(response.data)
      downloadFile('questionnaires.csv', 'data:text/csv;charset=UTF-8,' + userCsv)
    },
    async getQuestionnaires (isInitialLoad = true) {
      try {
        if (isInitialLoad) { this.isLoading = true }

        let response = {}
        let route = `/questionnaires/`
        await this.getSearchQuery()
        response = await this.axios.post(route, this.payload)
        this.totalRecords = response.data.page.totalRecords
        this.returned = response.data.page.totalReturned

        let filteredItems = filterBy(response.data.records, this.filter)
        if (filteredItems) { filteredItems = filteredItems.map(item => { return { ...item, selected: false } }) }
        this.questionnaires = orderBy(filteredItems, this.sort)
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    },
    pageChangeHandler: function (event) {
      this.skip = event.page.skip
      this.take = event.page.take
      this.perPage = event.page.take
      this.pageSize = event.page.take

      localStorage.setItem(`${storageKey}PageSize`, event.page.take)

      this.getQuestionnaires(false)
    },
    updatePagerState: function (key, value) {
      const newPageableState = Object.assign({}, this.pageable, { [key]: value })
      this.pageable = newPageableState
    },
    changeHandler: function (type, value) {
      this.updatePagerState(type, value)
    },
    sortChangeHandler: function (e) {
      this.sort = e.sort
      localStorage.setItem(`${storageKey}Sort`, JSON.stringify(this.sort))
      this.getQuestionnaires(false)
    },
    change: function (filter) {
      if (filter.syntheticEvent) { this.filter = filter.syntheticEvent.filter }
    },
    filterChangeHandler: debounce(function (e) {
      if (e.filter && !this.filters) {
        this.filters = e.filter.filters
      } else if (e.filter && this.filters) {
        e.filter.filters.forEach(element => {
          this.filters.replaceOrPush(element, function (e) {
            return e.field === element.field
          })
        })
      } else {
        if (e.event.value === '') { this.filters = this.filters.filter(x => x.field !== e.event.field) }
      }

      localStorage.setItem(`${storageKey}Filters`, JSON.stringify(this.filters))

      this.skip = 0
      this.getQuestionnaires(false)
    }, 500),
    filterRender: function (h, defaultRendering, props, change) {
      return defaultRendering
    },
    clearFilters: function () {
      // Manually clear filter values
      if (this.filters) {
        this.filters.forEach(element => {
          var filterInput = document.getElementById(element.field)
          if (filterInput) { filterInput.value = '' }
        })

        this.filters = []
      }

      this.skip = 0
      this.sort = [
        { field: 'id', dir: 'asc' }
      ]

      localStorage.removeItem(`${storageKey}Filters`)
      localStorage.removeItem(`${storageKey}Sort`)

      this.getQuestionnaires(false)
    }
  }
}
</script>

<style>
#body-content-area {
  position: absolute;
  top: 35px;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
}

#pagelayout {
  right: 100%;
  bottom: 0;
}
.k-widget k-dateinput k-state-default k-label{
  font-weight: 600 !important;
  padding: 8px;
}
.k-checkbox:checked{
  background-color:  #7fb942;
  color: white;
  border-color: #7fb942;
}
.k-pager-numbers .k-state-selected{
  background-color:  #7fb942;
  color: white;
  border-color: #7fb942;
}
.k-grid th{
  font-weight: 600 !important;
}
.k-grid td.k-state-selected, .k-grid tr.k-state-selected > td{
  background-color: #CBE3B3;
  opacity: 0.8;
  font-weight: 600;
}
.k-list .k-item.k-state-selected{
   background-color:  #7fb942;
}
.k-list .k-item.k-state-selected:hover{
  background-color: #CBE3B3;
  color: #0d0c0c
}
.page-subtitle{
  color: #7fb942;
  font-weight: 600;
  font-size: 1em;
  margin-bottom: 8px;
}
.reportDateStatus, .status {
    margin-top: 10px;
}
.k-invalid-msg {
    display: inline-block;
}
.invalid {
    color: red;
}
.valid {
    color: green;
}
.bcc-color{
   color: #7fb942;
}
.grid-button {
  margin-bottom: 5px;
}
.heading {
  font-size: 1.2em;
}
.subheading {
  font-size:.8em;
}
.grid-header-controls {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.questionnaires-header {
  display: flex;
}
.questionnaires-header > .column:first-child {
  width: 25%;
  padding: 0;
  flex-basis: 1;
}
.questionnaires-header > .column:last-child {
  width: 50%;
  text-align: center;
  padding: 0;
  flex-basis: 1;
}

</style>
